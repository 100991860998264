.Header {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;

}

.Header > img {
    margin-top: 30px;
    // width: 160px;
    // height: 160px;
}
