.NextShows {
    height: 40px;
    color: #888;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
}

.Test {
    opacity: 0;
    transform: translateY(-700px);
    transition: transform 200ms cubic-bezier(.42,0,.61,.91), opacity 100ms ease-in-out;
}
