.Separator {
    background-color: #212121;
    padding: 20px;
    color: #FFF;
    width: 360px;
    height: 860px;
    border: solid 1px #000000;
    box-sizing: border-box;
}

.Separator h2 {
    font-size: 46px;
    font-weight: 500;
    margin-top: 50px;
    margin-left: 25px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.Month1 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_1.jpg');
}

.Month2 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_2.jpg');
}

.Month3 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_3.jpg');
}

.Month4 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_4.jpg');
}

.Month5 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_5.jpg');
}

.Month6 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_6.jpg');
}

.Month7 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_7.jpg');
}

.Month8 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_8.jpg');
}


.Month9 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_9.jpg');
}


.Month10 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_10.jpg');
}


.Month11 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_11.jpg');
}


.Month12 {
    background-size: cover;
    background-image: url('../../assets/months/coliseu_mes_12.jpg');
}
