.FixedEventsContainer {
    position: relative;
    background-color: #000000;
    height: 1720px;
    overflow: hidden;
}

.ScrollEventsContainer {
    position: relative;
    background-color: #000000;
    height: 860px;
    overflow: hidden;
}

.EventsGrid {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: transform 1000ms cubic-bezier(.42,0,.61,.91);
}

.ScrollEventsContainer .EventsGrid {
    flex-wrap: nowrap;
}

.Loading {
    height: 2840px; /* events grid + progress */
    color: #FFFFFF;
    font-size: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
	opacity: 0;
    }
}
