.Progress {
   margin: 75px 0 0 0;
}

.Months {
    height: 50px;
    color: white;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Months span {
    margin: 0 25px 0 25px;
    font-size: 28px;
    color: #999;
}

.Months span.Active {
    color: #fff;
    font-weight: bold;
}

.ProgressBar {
    border-bottom: 2px solid white;
}
