.Event {
    background-color: #212121;
    padding: 20px;
    color: #dddddd;
    width: 360px;
    height: 860px;
    border: solid 1px #000000;
    box-sizing: border-box;
    font-weight: 300;
}

.EventNonActive {
    color: #999;
}

.EventName {
    font-weight: 500;
    font-size: 32px;
    line-height: 1.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-block-start: 0;
}

.EventDate {
    font-size: 26px;
    margin-block-end: 0;
}

.EventTime {
    font-size: 26px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.Info {
    font-size: 14px;
    margin-bottom: 30px;
    line-height: 1.4em;
}

.Info p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.NonActiveMarker {
    color: #c93;
    margin-block-start: 0;
}
